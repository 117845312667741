function IconMarketCoin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      fill="currentColor"
      {...props}
      viewBox="0 0 256 256"
    >
      <defs>
        <clipPath id="clip-1">
          <path
            fill="none"
            d="M0 256V0h256v256h-46.137c20.924-9.473 35.48-30.535 35.48-55 0-33.33-27.014-60.343-60.343-60.343-33.33 0-60.343 27.014-60.343 60.343 0 24.465 14.556 45.527 35.48 55z"
          ></path>
        </clipPath>
      </defs>
      <g
        fill="none"
        strokeMiterlimit="10"
        clipPath="url(#clip-1)"
        fontFamily="none"
        fontSize="none"
        fontWeight="none"
        textAnchor="none"
        style={{ mixBlendMode: "normal" }}
      >
        <path
          fill="currentColor"
          d="M6 3a2 2 0 00-2 2v1l-3 4a2 2 0 104 0l2-4h3l-1 4a2 2 0 104 0V6h4v4a2 2 0 104 0l-1-4h3l2 4a2 2 0 104 0l-3-4V5a2 2 0 00-2-2zm7 10.443A3.96 3.96 0 0111 14a3.95 3.95 0 01-1.994-.555A3.957 3.957 0 017 14c-.727 0-1.4-.21-1.988-.55A3.965 3.965 0 013 14v11a2 2 0 002 2h12V17h6v10h2a2 2 0 002-2V14c-.734 0-1.42-.204-2.012-.55-.588.34-1.261.55-1.988.55a3.957 3.957 0 01-2.006-.555A3.95 3.95 0 0119 14a3.96 3.96 0 01-2-.557A3.96 3.96 0 0115 14a3.96 3.96 0 01-2-.557zM7 17h6v6H7z"
          transform="scale(8.53333)"
        ></path>
      </g>
      <g
        fill="currentColor"
        strokeMiterlimit="10"
        fontFamily="none"
        fontSize="none"
        fontWeight="none"
        textAnchor="none"
        style={{ mixBlendMode: "normal" }}
      >
        <g transform="translate(138.067 154.067) scale(6.70476)">
          <path d="M7.23 7.47H6.16v1.89h1.09c.73 0 1.13-.34 1.13-.96 0-.6-.41-.93-1.15-.93zm-.05-2.73H6.16v1.68h.94c.66 0 1.04-.32 1.04-.84s-.35-.84-.96-.84zm.05 2.73H6.16v1.89h1.09c.73 0 1.13-.34 1.13-.96 0-.6-.41-.93-1.15-.93zm.91-1.89c0-.52-.35-.84-.96-.84H6.16v1.68h.94c.66 0 1.04-.32 1.04-.84zM7 0C3.13 0 0 3.13 0 7s3.13 7 7 7 7-3.13 7-7-3.13-7-7-7zm1.3 10.51v.99H7.1v-.93h-.8v.93H5.1v-.93h-.53V3.53h.53V2.5h1.2v1.03h.8V2.5h1.2v1.11c.88.2 1.39.79 1.39 1.67 0 .76-.52 1.4-1.22 1.51v.09c.87.05 1.54.77 1.54 1.67 0 1.04-.65 1.76-1.71 1.96zM7.23 7.47H6.16v1.89h1.09c.73 0 1.13-.34 1.13-.96 0-.6-.41-.93-1.15-.93zm.91-1.89c0-.52-.35-.84-.96-.84H6.16v1.68h.94c.66 0 1.04-.32 1.04-.84z"></path>
        </g>
      </g>
    </svg>
  );
}

export default IconMarketCoin;
