import React from "react";

export function IconFlagUK(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      {...props}
      viewBox="0 0 48 48"
    >
      <path
        fill="#3f51b5"
        d="M19,4.656c-4.055,1.046-7.612,3.316-10.243,6.413L19,17.331V4.656z"
      ></path>
      <path
        fill="#3f51b5"
        d="M8.605,36.764c2.644,3.185,6.265,5.515,10.395,6.581V30.41L8.605,36.764z"
      ></path>
      <path
        fill="#fff"
        d="M4.656,29c0.013,0.052,0.026,0.103,0.04,0.155L4.948,29H4.656z"
      ></path>
      <path
        fill="#3f51b5"
        d="M39.576,11.469C36.916,8.168,33.228,5.746,29,4.656v13.277L39.576,11.469z"
      ></path>
      <path
        fill="#3f51b5"
        d="M29,43.344c4.303-1.11,8.041-3.603,10.711-6.994L29,29.804V43.344z"
      ></path>
      <path
        fill="#fff"
        d="M4.753,18.621C4.718,18.746,4.688,18.874,4.656,19h0.717L4.753,18.621z"
      ></path>
      <path
        fill="#fff"
        d="M43.751,21c-0.095-0.63-0.218-1.249-0.37-1.858L40.342,21H43.751z"
      ></path>
      <path
        fill="#fff"
        d="M5.373,19H4.656c-0.169,0.655-0.304,1.321-0.407,2h4.396L5.373,19z"
      ></path>
      <path
        fill="#fff"
        d="M43.44,28.629c0.127-0.535,0.228-1.079,0.311-1.629h-2.977L43.44,28.629z"
      ></path>
      <path
        fill="#fff"
        d="M8.22,27H4.249c0.102,0.679,0.238,1.345,0.407,2h0.293L8.22,27z"
      ></path>
      <path
        fill="#fff"
        d="M21,18.553V4.249c-0.679,0.102-1.345,0.238-2,0.407v12.675L21,18.553z"
      ></path>
      <path
        fill="#fff"
        d="M27,28.582v15.169c0.679-0.102,1.345-0.238,2-0.407v-13.54L27,28.582z"
      ></path>
      <path
        fill="#fff"
        d="M19,30.41v12.934c0.655,0.169,1.321,0.304,2,0.407V29.188L19,30.41z"
      ></path>
      <path
        fill="#fff"
        d="M27.185,18.694l0.284,0.174L29,17.932V4.656c-0.655-0.169-1.321-0.304-2-0.407v14.748L27.185,18.694z"
      ></path>
      <path
        fill="#fff"
        d="M8.645,21h9.39L21,19.188v-0.634l-2-1.223L8.757,11.069c-0.854,1.006-1.613,2.094-2.258,3.257	L14.05,19H8.594l-3.293-2.047c-0.206,0.546-0.39,1.101-0.549,1.668L5.373,19L8.645,21z"
      ></path>
      <path
        fill="#fff"
        d="M27.469,18.868L30.957,21h9.385l3.039-1.858c-0.325-1.3-0.775-2.547-1.341-3.732L36.278,19h-5.456	l9.812-6.1c-0.331-0.494-0.687-0.969-1.059-1.431L29,17.932L27.469,18.868z"
      ></path>
      <path
        fill="#e53935"
        d="M14.05,19L6.5,14.326c-0.465,0.839-0.856,1.72-1.198,2.627L8.594,19H14.05z"
      ></path>
      <path
        fill="#e53935"
        d="M36.278,19l5.763-3.59c-0.415-0.87-0.874-1.714-1.406-2.509L30.822,19H36.278z"
      ></path>
      <path
        fill="#fff"
        d="M27,27v1.582l2,1.222l10.711,6.547c0.772-0.981,1.456-2.033,2.036-3.15L34.971,29h5.456l2.459,1.534	c0.215-0.622,0.4-1.256,0.553-1.904L40.774,27H27z"
      ></path>
      <polygon
        fill="#fff"
        points="30.957,21 27.469,18.868 27.185,18.694 27,18.997 27,21"
      ></polygon>
      <path
        fill="#fff"
        d="M21,29.188V27H8.22l-3.272,2l-0.253,0.155c0.352,1.321,0.832,2.587,1.433,3.786L12.329,29h5.456	L7.603,35.433c0.318,0.456,0.648,0.904,1.002,1.331L19,30.41L21,29.188z"
      ></path>
      <polygon fill="#fff" points="18.035,21 21,21 21,19.188"></polygon>
      <path
        fill="#e53935"
        d="M34.971,29l6.775,4.2c0.444-0.854,0.821-1.746,1.14-2.667L40.427,29H34.971z"
      ></path>
      <path
        fill="#e53935"
        d="M12.329,29l-6.201,3.941c0.435,0.868,0.923,1.702,1.475,2.492L17.785,29H12.329z"
      ></path>
      <path
        fill="#e53935"
        d="M30.957,21H27v6h13.774h2.977C43.899,26.019,44,25.022,44,24s-0.101-2.019-0.249-3h-3.409H30.957z"
      ></path>
      <path
        fill="#e53935"
        d="M21,27v-6h-2.965h-9.39H4.249C4.101,21.981,4,22.978,4,24s0.101,2.019,0.249,3H8.22H21z"
      ></path>
      <path
        fill="#e53935"
        d="M27,43.751V28.582V27v-6v-2.003V4.249C26.019,4.101,25.022,4,24,4s-2.019,0.101-3,0.249v14.304v0.634	V21v6v2.188v14.563C21.981,43.899,22.978,44,24,44S26.019,43.899,27,43.751z"
      ></path>
    </svg>
  );
}
