/**
 * This code was generated by v0 by Vercel.
 * @see https://v0.dev/t/vMzZ4wev4IQ
 */

import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { isAddress } from "viem";
import { useConnectorContext } from "../../hooks/connectors/useConnectorContext";
import { usePointService } from "../../hooks/services/backend/usePointService";
import { useUserService } from "../../hooks/services/backend/useUserService";
import { useToast } from "../../hooks/useToast";
import { logError, toBN } from "../../libs/helpers";
import Input2 from "../Input2";
import IconCheckCircle from "../icons/IconCheckCircle";
import IconSpinner from "../icons/IconSpinner";
import { Button } from "../ui/Button";

export function NFTConversion() {
  const { address, ready, sign } = useConnectorContext();
  const { getNFTInfo } = useUserService();
  const [nftCount, setNftCount] = useState(0);
  const [totalPoints, setTotalPoints] = useState("0");
  const { createPointFromNFTOwned, checkPointExists } = usePointService();
  const [beneficiaryAddress, setBeneficiaryAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const { notifyError, notifySuccess } = useToast();

  const getNFTInfoQuery = useQuery({
    queryKey: ["getNFTInfo", { address }],
    queryFn: getNFTInfo,
    enabled: ready,
    refetchInterval: false,
  });

  const createPoint = useMutation({
    mutationFn: createPointFromNFTOwned,
  });

  const isPointGrantedQuery = useQuery({
    queryKey: ["isPointGranted", `nft_owned:${address}`],
    queryFn: checkPointExists,
    enabled: !!address && ready,
    refetchInterval: 60000,
  });

  useEffect(() => {
    if (getNFTInfoQuery.isLoading) return;
    if (getNFTInfoQuery.data) {
      setNftCount(getNFTInfoQuery.data.count);
      setTotalPoints(getNFTInfoQuery.data.totalPoints);
    }
  }, [getNFTInfoQuery.isLoading, getNFTInfoQuery.data]);

  function canTransfer() {
    return (
      !loading &&
      ready &&
      isPointGrantedQuery.data == false &&
      beneficiaryAddress &&
      isAddress(beneficiaryAddress) &&
      toBN(totalPoints).gt(0)
    );
  }

  async function doTransfer() {
    if (!canTransfer()) return;
    setLoading(true);
    try {
      const sig = await sign(`beneficiary: ${beneficiaryAddress}`);
      await createPoint.mutateAsync({
        signer: address,
        beneficiary: beneficiaryAddress,
        sig,
      });
      await isPointGrantedQuery.refetch();
      notifySuccess("Points transferred successfully!", { label: "Success" });
    } catch (error) {
      notifyError((error as { message: string }).message, { label: "Error" });
      logError("doTransfer:", error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="flex flex-col gap-y-3 w-[400px] px-1 xxs:px-3 sm:px-5 md:px-0">
      <div className="bg-card-background border border-card-border p-6 w-full max-w-md mx-auto rounded-lg shadow-md flex flex-col">
        <div className="mb-4 flex justify-center items-center">
          <WalletIcon className="w-4 h-4 mr-2" />
          <h1 className="text-sm md:text-lg font-semibold tracking-wider">
            Total Owned / Points Earned:
          </h1>
        </div>
        <p className="text-gray-200 text-lg text-center flex gap-2 justify-center items-center">
          <span>{nftCount}</span>
          <span className="text-orange-500 text-sm">
            ({totalPoints} Points)
          </span>
        </p>
      </div>

      {isPointGrantedQuery.data == false && totalPoints != "0" && (
        <div className="bg-card-background border border-card-border p-6 w-full max-w-md mx-auto rounded-lg shadow-md flex flex-col items-center">
          <div className="w-full mb-4 flex flex-col gap-4">
            <div>
              <label
                className="block font-medium text-gray-300 mb-2 tracking-wider"
                htmlFor="points"
              >
                Transfer Points
              </label>
              <p className="text-xs font-light tracking-wider text-gray-400">
                Transfer points earned to an address of your choice. The
                beneficiary address will receive the points.{" "}
                <i>The action can note be reversed.</i>
              </p>
            </div>
            <Input2
              placeholder="Beneficiary Address"
              value={beneficiaryAddress}
              onChange={(e) => setBeneficiaryAddress(e.target.value)}
              afterInput={
                <div className="mt-1 mr-1 relative -top-0.5">
                  <Button
                    variant="link"
                    size="xs"
                    onClick={() => {
                      if (!address) return;
                      setBeneficiaryAddress(address || "");
                    }}
                  >
                    SELF
                  </Button>
                </div>
              }
            />
          </div>

          <div className="flex flex-col gap-2 w-full mt-2 border-t pt-3 border-gray-800">
            <p className="text-center font-light text-xs text-gray-400">
              You will be asked to sign. No transaction is created.
            </p>
            <Button
              variant="default"
              size="full"
              disabled={!canTransfer()}
              onClick={doTransfer}
            >
              {!loading && "Transfer"}
              {loading && (
                <IconSpinner
                  width="20"
                  className="animate-spin"
                  fill="fill-gray-900"
                />
              )}
            </Button>
          </div>
        </div>
      )}

      {isPointGrantedQuery.data && (
        <div className="bg-card-background border border-card-border p-6 w-full max-w-md mx-auto rounded-lg shadow-md flex flex-col items-center">
          <label
            className="text-sm font-medium text-center text-green-500 tracking-wider flex gap-2"
            htmlFor="points"
          >
            <span>
              <IconCheckCircle width="20" />
            </span>
            <span>Points Claimed</span>
          </label>
        </div>
      )}
    </div>
  );
}

function WalletIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 12V7H5a2 2 0 0 1 0-4h14v4" />
      <path d="M3 5v14a2 2 0 0 0 2 2h16v-5" />
      <path d="M18 12a2 2 0 0 0 0 4h4v-4Z" />
    </svg>
  );
}
