import React from "react";

export default function IconOffer(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
      viewBox="0 0 22 22"
    >
      <path d="M 9.1523438 2.0019531 C 8.7590156 1.9770625 8.3695781 2.1798281 8.1738281 2.5488281 L 6.890625 4.96875 L 4.1933594 5.4414062 C 3.6443594 5.5374063 3.2716094 6.0515156 3.3496094 6.6035156 L 3.7324219 9.3144531 L 1.828125 11.28125 C 1.440125 11.68225 1.440125 12.31775 1.828125 12.71875 L 3.7324219 14.6875 L 3.3496094 17.398438 C 3.2716094 17.950437 3.6443594 18.463547 4.1933594 18.560547 L 6.890625 19.033203 L 8.1738281 21.451172 C 8.4348281 21.943172 9.0400156 22.141484 9.5410156 21.896484 L 12 20.693359 L 14.460938 21.894531 C 14.961937 22.139531 15.564172 21.943172 15.826172 21.451172 L 17.109375 19.033203 L 19.806641 18.560547 C 20.355641 18.464547 20.730344 17.948484 20.652344 17.396484 L 20.269531 14.685547 L 22.173828 12.71875 C 22.558828 12.31875 22.558875 11.68225 22.171875 11.28125 L 20.267578 9.3125 L 20.650391 6.6015625 C 20.728391 6.0495625 20.353687 5.5364531 19.804688 5.4394531 L 17.107422 4.9667969 L 15.824219 2.5488281 C 15.564219 2.0578281 14.960937 1.8604688 14.460938 2.1054688 L 12 3.3066406 L 9.5390625 2.1054688 C 9.4140625 2.0442188 9.2834531 2.01025 9.1523438 2.0019531 z M 9 7 C 10.105 7 11 7.895 11 9 C 11 10.105 10.105 11 9 11 C 7.895 11 7 10.105 7 9 C 7 7.895 7.895 7 9 7 z M 14.443359 7.8574219 C 14.633359 7.8301719 14.8345 7.876 15 8 C 15.331 8.249 15.398391 8.7178281 15.150391 9.0488281 L 10.050781 15.849609 C 9.8017812 16.181609 9.331 16.248 9 16 C 8.669 15.751 8.6016094 15.282172 8.8496094 14.951172 L 13.949219 8.1503906 C 14.073719 7.9843906 14.253359 7.8846719 14.443359 7.8574219 z M 9 8 A 1 1 0 0 0 8 9 A 1 1 0 0 0 9 10 A 1 1 0 0 0 10 9 A 1 1 0 0 0 9 8 z M 15 13 C 16.105 13 17 13.895 17 15 C 17 16.105 16.105 17 15 17 C 13.895 17 13 16.105 13 15 C 13 13.895 13.895 13 15 13 z M 15 14 A 1 1 0 0 0 14 15 A 1 1 0 0 0 15 16 A 1 1 0 0 0 16 15 A 1 1 0 0 0 15 14 z"></path>
    </svg>
  );
}
