import React from "react";

export function IconFlagNigeria(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      {...props}
      viewBox="0 0 48 48"
    >
      <path
        fill="#eceff1"
        d="M32,5.675C29.549,4.604,26.846,4,24,4s-5.549,0.604-8,1.675v36.649C18.451,43.396,21.154,44,24,44 s5.549-0.604,8-1.675V5.675z"
      ></path>
      <path
        fill="#388e3c"
        d="M44,24c0-8.199-4.939-15.237-12-18.325v36.649C39.061,39.237,44,32.199,44,24z"
      ></path>
      <path
        fill="#388e3c"
        d="M4,24c0,8.199,4.939,15.237,12,18.325V5.675C8.939,8.763,4,15.801,4,24z"
      ></path>
    </svg>
  );
}
