import React from "react";

export function IconFlagUSA(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      {...props}
      viewBox="0 0 48 48"
    >
      <path
        fill="#3f51b5"
        d="M30,5V4.918C28.106,4.323,26.091,4,24,4C12.954,4,4,12.954,4,24c0,0.675,0.036,1.342,0.101,2H30V5z"
      ></path>
      <path
        fill="none"
        d="M30.223,5C30.147,4.975,30.076,4.942,30,4.918V5H30.223z"
      ></path>
      <path
        fill="#eceff1"
        d="M30,11h9.18c-0.957-1.116-2.038-2.117-3.217-3H30V11z"
      ></path>
      <path
        fill="#eceff1"
        d="M43.899,26H30H4.101c0.102,1.028,0.303,2.024,0.555,3h38.689C43.596,28.024,43.797,27.028,43.899,26z"
      ></path>
      <path
        fill="#eceff1"
        d="M38.271,38H9.729c1.127,1.149,2.398,2.149,3.773,3h20.996C35.873,40.149,37.144,39.149,38.271,38z"
      ></path>
      <path
        fill="#eceff1"
        d="M42.325,32H5.675c0.459,1.05,1.005,2.053,1.63,3h33.39C41.32,34.053,41.865,33.05,42.325,32z"
      ></path>
      <path
        fill="#eceff1"
        d="M30,17h12.716c-0.391-1.044-0.861-2.047-1.413-3H30V17z"
      ></path>
      <path
        fill="#eceff1"
        d="M30,23h13.95c-0.051-1.02-0.153-2.026-0.351-3H30V23z"
      ></path>
      <path
        fill="#f44336"
        d="M30,8h5.964c-1.723-1.29-3.651-2.316-5.741-3H30V8z"
      ></path>
      <path
        fill="#f44336"
        d="M30,14h11.303c-0.617-1.066-1.326-2.07-2.122-3H30V14z"
      ></path>
      <path
        fill="#f44336"
        d="M30,20h13.598c-0.21-1.034-0.52-2.03-0.883-3H30V20z"
      ></path>
      <path
        fill="#f44336"
        d="M30,26h13.899C43.964,25.342,44,24.675,44,24c0-0.338-0.034-0.667-0.05-1H30V26z"
      ></path>
      <path
        fill="#f44336"
        d="M43.344,29H4.656c0.266,1.033,0.599,2.039,1.02,3h36.649C42.745,31.039,43.078,30.033,43.344,29z"
      ></path>
      <path
        fill="#f44336"
        d="M40.695,35H7.305c0.711,1.078,1.523,2.082,2.424,3h28.543C39.172,37.082,39.984,36.078,40.695,35z"
      ></path>
      <path
        fill="#f44336"
        d="M13.502,41c3.054,1.89,6.642,3,10.498,3s7.444-1.11,10.498-3H13.502z"
      ></path>
      <path
        fill="#fff"
        d="M10.25,13l0.465,0.988l1.035,0.156L11,14.914L11.18,16l-0.93-0.512L9.32,16l0.18-1.086l-0.75-0.77	l1.035-0.156L10.25,13z"
      ></path>
      <path
        fill="#fff"
        d="M14.25,13l0.465,0.988l1.035,0.156L15,14.914L15.18,16l-0.93-0.512L13.32,16l0.18-1.086l-0.75-0.77	l1.035-0.156L14.25,13z"
      ></path>
      <path
        fill="#fff"
        d="M6.25,15.488L7.18,16L7,14.914l0.75-0.77l-1.035-0.156l-0.004-0.01	c-0.33,0.568-0.633,1.153-0.907,1.755L6.25,15.488z"
      ></path>
      <path
        fill="#fff"
        d="M18.25,13l0.465,0.988l1.035,0.156L19,14.914L19.18,16l-0.93-0.512L17.32,16l0.18-1.086l-0.75-0.77	l1.035-0.156L18.25,13z"
      ></path>
      <path
        fill="#fff"
        d="M22.25,13l0.465,0.988l1.035,0.156L23,14.914L23.18,16l-0.93-0.512L21.32,16l0.18-1.086l-0.75-0.77	l1.035-0.156L22.25,13z"
      ></path>
      <path
        fill="#fff"
        d="M26,13l0.465,0.988l1.035,0.156l-0.75,0.77L26.93,16L26,15.488L25.07,16l0.18-1.086l-0.75-0.77	l1.035-0.156L26,13z"
      ></path>
      <path
        fill="#fff"
        d="M10.25,21l0.465,0.988l1.035,0.156L11,22.914L11.18,24l-0.93-0.512L9.32,24l0.18-1.086l-0.75-0.77	l1.035-0.156L10.25,21z"
      ></path>
      <path
        fill="#fff"
        d="M14.25,21l0.465,0.988l1.035,0.156L15,22.914L15.18,24l-0.93-0.512L13.32,24l0.18-1.086l-0.75-0.77	l1.035-0.156L14.25,21z"
      ></path>
      <path
        fill="#fff"
        d="M18.25,21l0.465,0.988l1.035,0.156L19,22.914L19.18,24l-0.93-0.512L17.32,24l0.18-1.086l-0.75-0.77	l1.035-0.156L18.25,21z"
      ></path>
      <path
        fill="#fff"
        d="M6.25,21l0.465,0.988l1.035,0.156L7,22.914L7.18,24l-0.93-0.512L5.32,24l0.18-1.086l-0.75-0.77	l1.035-0.156L6.25,21z"
      ></path>
      <path
        fill="#fff"
        d="M22.25,21l0.465,0.988l1.035,0.156L23,22.914L23.18,24l-0.93-0.512L21.32,24l0.18-1.086l-0.75-0.77	l1.035-0.156L22.25,21z"
      ></path>
      <path
        fill="#fff"
        d="M26,21l0.465,0.988l1.035,0.156l-0.75,0.77L26.93,24L26,23.488L25.07,24l0.18-1.086l-0.75-0.77	l1.035-0.156L26,21z"
      ></path>
      <path
        fill="#fff"
        d="M11.25,17l0.465,0.988l1.035,0.156L12,18.914L12.18,20l-0.93-0.512L10.32,20l0.18-1.086l-0.75-0.77	l1.035-0.156L11.25,17z"
      ></path>
      <path
        fill="#fff"
        d="M15.25,17l0.465,0.988l1.035,0.156L16,18.914L16.18,20l-0.93-0.512L14.32,20l0.18-1.086l-0.75-0.77	l1.035-0.156L15.25,17z"
      ></path>
      <path
        fill="#fff"
        d="M7.25,17l0.465,0.988l1.035,0.156L8,18.914L8.18,20l-0.93-0.512L6.32,20l0.18-1.086l-0.75-0.77	l1.035-0.156L7.25,17z"
      ></path>
      <path
        fill="#fff"
        d="M19.25,17l0.465,0.988l1.035,0.156L20,18.914L20.18,20l-0.93-0.512L18.32,20l0.18-1.086l-0.75-0.77	l1.035-0.156L19.25,17z"
      ></path>
      <path
        fill="#fff"
        d="M23.25,17l0.465,0.988l1.035,0.156L24,18.914L24.18,20l-0.93-0.512L22.32,20l0.18-1.086l-0.75-0.77	l1.035-0.156L23.25,17z"
      ></path>
      <path
        fill="#fff"
        d="M27,17l0.465,0.988l1.035,0.156l-0.75,0.77L27.93,20L27,19.488L26.07,20l0.18-1.086l-0.75-0.77	l1.035-0.156L27,17z"
      ></path>
      <path
        fill="#fff"
        d="M11.25,9l0.465,0.988l1.035,0.156L12,10.914L12.18,12l-0.93-0.512L10.32,12l0.18-1.086l-0.75-0.77	l1.035-0.156L11.25,9z"
      ></path>
      <path
        fill="#fff"
        d="M15.25,9l0.465,0.988l1.035,0.156L16,10.914L16.18,12l-0.93-0.512L14.32,12l0.18-1.086l-0.75-0.77	l1.035-0.156L15.25,9z"
      ></path>
      <path
        fill="#fff"
        d="M19.25,9l0.465,0.988l1.035,0.156L20,10.914L20.18,12l-0.93-0.512L18.32,12l0.18-1.086l-0.75-0.77	l1.035-0.156L19.25,9z"
      ></path>
      <path
        fill="#fff"
        d="M23.25,9l0.465,0.988l1.035,0.156L24,10.914L24.18,12l-0.93-0.512L22.32,12l0.18-1.086l-0.75-0.77	l1.035-0.156L23.25,9z"
      ></path>
      <path
        fill="#fff"
        d="M27,9l0.465,0.988l1.035,0.156l-0.75,0.77L27.93,12L27,11.488L26.07,12l0.18-1.086l-0.75-0.77	l1.035-0.156L27,9z"
      ></path>
      <path
        fill="#fff"
        d="M15.18,8L15,6.914l0.75-0.77l-0.551-0.083c-0.589,0.289-1.16,0.606-1.715,0.95L13.32,8l0.93-0.512	L15.18,8z"
      ></path>
      <path
        fill="#fff"
        d="M18.25,5l0.465,0.988l1.035,0.156L19,6.914L19.18,8l-0.93-0.512L17.32,8l0.18-1.086l-0.75-0.77	l1.035-0.156L18.25,5z"
      ></path>
      <path
        fill="#fff"
        d="M22.25,5l0.465,0.988l1.035,0.156L23,6.914L23.18,8l-0.93-0.512L21.32,8l0.18-1.086l-0.75-0.77	l1.035-0.156L22.25,5z"
      ></path>
      <path
        fill="#fff"
        d="M26,5l0.465,0.988L27.5,6.145l-0.75,0.77L26.93,8L26,7.488L25.07,8l0.18-1.086l-0.75-0.77	l1.035-0.156L26,5z"
      ></path>
    </svg>
  );
}
