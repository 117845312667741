import React from "react";
import { NavLink } from "react-router-dom";
import { Button } from "./ui/Button";

export function IconQuestionCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 1024 1024"
      fill="currentColor"
      height="20px"
      width="20px"
      {...props}
    >
      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 708c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40-17.9 40-40 40zm62.9-219.5a48.3 48.3 0 00-30.9 44.8V620c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8v-21.5c0-23.1 6.7-45.9 19.9-64.9 12.9-18.6 30.9-32.8 52.1-40.9 34-13.1 56-41.6 56-72.7 0-44.1-43.1-80-96-80s-96 35.9-96 80v7.6c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V420c0-39.3 17.2-76 48.4-103.3C430.4 290.4 470 276 512 276s81.6 14.5 111.6 40.7C654.8 344 672 380.7 672 420c0 57.8-38.1 109.8-97.1 132.5z" />
    </svg>
  );
}

export function NavHelpButton() {
  return (
    <div className="hidden lg:block">
      <NavLink target="_blank" to="https://docs.joint.exchange">
        <Button
          variant="ghost"
          className="hidden lg:flex justify-center items-center h-[40px] w-[40px] rounded-full border hover:bg-gray-800 border-gray-600 cursor-pointer text-gray-200 hover:border-chinese-green hover:text-chinese-green transition-all duration-500 hover:scale-110"
        >
          <div>
            <IconQuestionCircle />
          </div>
        </Button>
      </NavLink>
    </div>
  );
}
