import React from "react";

export function IconFlagJapan(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      {...props}
      viewBox="0 0 48 48"
    >
      <circle cx="24" cy="24" r="20" fill="#eceff1"></circle>
      <path
        fill="#d50000"
        d="M33,24c0,4.969-4.031,9-9,9s-9-4.031-9-9s4.031-9,9-9S33,19.031,33,24z"
      ></path>
    </svg>
  );
}
