export enum Events {
	LiquidityAdd = 'liq_add',
	LiquidityRemove = 'liq_remove',
	LiquiditySwap = 'liq_swap',
	LiquidityOffer = 'liq_offer',
	LiquidityPause = 'liq_pause',
	LiquidityManagerUpdate = 'liq_manager_update',
	LiquidityTargetUpdate = 'liq_target_update',
	LiquidityPriceUpdate = 'liq_price_update',
	LiquiditySwapLimitUpdate = 'liq_swap_limit_update',
	LiquidityTimeToPayUpdate = 'liq_time_to_pay_update',
	SwapNew = 'swap_new',
	SwapPaid = 'swap_paid',
	SwapReleased = 'swap_released',
	SwapCancelled = 'swap_cancelled',
	SwapDisputed = 'swap_disputed',
	SwapDrafted = 'swap_drafted',
	SwapCommitted = 'swap_committed',
	SwapRevealed = 'swap_revealed',
	SwapExecuted = 'swap_executed',
	SwapUndrafted = 'swap_undrafted',
	OfferCreate = 'offer_create',
	OfferUpdate = 'offer_update',
	OfferAccept = 'offer_accept',
	OfferUnaccept = 'offer_unaccept',
	OfferCancel = 'offer_cancel',
	OfferExecute = 'offer_execute',
}

export enum AssetType {
	SYNTH,
	ERC20,
	ERC721,
	ERC1155,
}

export enum NotificationType {
	TypeChatNotification = 'chat:new-message',
}

export enum NotificationType {
	InstantSwap = 'swap:instant',
	InteractiveSwap = 'swap:interactive',
	OrderCancellation = 'order:cancellation',
	OrderPaid = 'order:paid',
	OrderReleased = 'order:released',
	PutApproved = 'market:perm:put:approved',
	PutRevoked = 'market:perm:put:revoked',
	SwapApproved = 'market:perm:swap:approved',
	SwapRevoked = 'market:perm:swap:revoked',
	NewLiqManager = 'market:liquidity-manager:updated',
	RemovedLiqManager = 'market:liquidity-manager:removed',
	DisputeCreated = 'dispute:created',
	DisputeTicketDrafted = 'dispute:ticket-drafted',
	DisputeEvidenceSubmission = 'dispute:evidence-submission',
	EvidencePhaseStarted = 'dispute:evidence-phase-started',
	DisputeTicketSlashed = 'dispute:ticket-slashed',
	EvidencePhaseEnded = 'dispute:evidence-phase-ended',
	CommitPhaseEnded = 'dispute:commit-phase-ended',
	RevealPhaseEnded = 'dispute:reveal-phase-ended',
	DisputeExecuted = 'dispute:executed',
	NewOffer = 'offer:new',
	OfferUpdated = 'offer:updated',
	OfferAccepted = 'offer:accepted',
	OfferUnaccepted = 'offer:unaccepted',
	OfferCancelled = 'offer:cancelled',
	OfferExecuted = 'offer:executed',
	ChatNewMessage = 'chat:new-message',
}
