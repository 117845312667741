import React from "react";

export function IconFlagSpain(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      {...props}
      viewBox="0 0 48 48"
    >
      <path
        fill="#dd2c00"
        d="M24,4C16.602,4,10.157,8.028,6.697,14h34.605C37.843,8.028,31.398,4,24,4z"
      ></path>
      <path
        fill="#dd2c00"
        d="M41.302,34H6.698c3.46,5.972,9.904,10,17.302,10S37.843,39.972,41.302,34z"
      ></path>
      <path
        fill="#ffc107"
        d="M44,24c0-3.647-0.992-7.056-2.697-10H6.697C4.992,16.944,4,20.353,4,24s0.992,7.056,2.698,10h34.605 C43.008,31.056,44,27.647,44,24z"
      ></path>
    </svg>
  );
}
