import React from "react";

export function IconMarket(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
      viewBox="0 0 30 30"
    >
      <path d="M 6 3 C 4.895 3 4 3.895 4 5 L 4 9 L 1 13 C 1 14.105 1.895 15 3 15 C 4.105 15 5 14.105 5 13 L 7 9 L 10 9 L 9 13 C 9 14.105 9.895 15 11 15 C 12.105 15 13 14.105 13 13 L 13 9 L 17 9 L 17 13 C 17 14.105 17.895 15 19 15 C 20.105 15 21 14.105 21 13 L 20 9 L 23 9 L 25 13 C 25 14.105 25.895 15 27 15 C 28.105 15 29 14.105 29 13 L 26 9 L 26 5 C 26 3.895 25.105 3 24 3 L 6 3 z M 7 5 C 7.552 5 8 5.448 8 6 C 8 6.552 7.552 7 7 7 C 6.448 7 6 6.552 6 6 C 6 5.448 6.448 5 7 5 z M 11 5 C 11.552 5 12 5.448 12 6 C 12 6.552 11.552 7 11 7 C 10.448 7 10 6.552 10 6 C 10 5.448 10.448 5 11 5 z M 4 17 L 4 24 C 4 25.64497 5.3550302 27 7 27 L 23 27 C 24.64497 27 26 25.64497 26 24 L 26 17 L 24 17 L 24 24 C 24 24.56503 23.56503 25 23 25 L 7 25 C 6.4349698 25 6 24.56503 6 24 L 6 17 L 4 17 z"></path>
    </svg>
  );
}
