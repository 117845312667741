import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../../hooks/store/useStore.ts";
import { useUrlQuery } from "../../hooks/useUrlQuery.ts";
import { rmUndefinedAndNull } from "../../libs/helpers.ts";
import Calendar2 from "../Calendar2.tsx";
import { FilterItem } from "../FilterItem.tsx";
import Input2 from "../Input2.tsx";
import ScrollOverflowIndicator from "../ScrollOverflowIndicator.tsx";
import Select2 from "../Select2.tsx";
import { Button } from "../ui/Button.tsx";
import { Accordion } from "../ui/accordion.tsx";
import { ScrollArea } from "../ui/scroll-area.tsx";
import { SelectItem } from "../ui/select.tsx";

interface Values {
  base?: string;
  quote?: string;
  market?: string;
  type?: string;
  status?: string;
  from_date?: string;
  to_date?: string;
}

export function SwapFilterContent() {
  const query = useUrlQuery();
  const navigate = useNavigate();
  const { setSwapFilter, swapFilter } = useStore((state) => ({
    setSwapFilter: state.setSwapFilter,
    swapFilter: state.swapFilter,
  }));
  const [values, setValues] = useState<Values>({});

  // Load filter fields default values from market filter in store
  useEffect(() => {
    if (Object.keys(swapFilter).length === 0) return;

    const update = {
      ...values,
      base: swapFilter?.base || "",
      quote: swapFilter?.quote || "",
      market: swapFilter?.marketAddress || "",
      type: swapFilter?.type || "",
      status: swapFilter?.status || "",
      from_date: swapFilter?.fromDate
        ? moment(swapFilter.fromDate).toDate().toLocaleDateString("en-US")
        : "",
      to_date: swapFilter?.toDate
        ? moment(swapFilter.toDate).toDate().toLocaleDateString("en-US")
        : "",
    };

    setValues(update);
  }, [swapFilter]);

  // Build filter from query params
  useEffect(() => {
    const filter: Partial<SwapFilter> = {};
    filter.base = query.get("base") as string;
    filter.quote = query.get("quote") as string;
    filter.marketAddress = query.get("market") as string;
    filter.type = query.get("type") as string;
    filter.status = query.get("status") as string;
    filter.fromDate = moment(
      query.get("from_date") as string,
      "MM/DD/YYYY",
    ).toISOString();
    filter.toDate = moment(
      query.get("to_date") as string,
      "MM/DD/YYYY",
    ).toISOString();

    setSwapFilter && setSwapFilter(filter);
  }, [query, setSwapFilter]);

  return (
    <div className="flex flex-col h-full">
      <ScrollOverflowIndicator side="bottom" className="flex-1 h-full">
        <ScrollArea
          type="scroll"
          viewportClassName="absolute xl:relative"
          className="h-full relative"
        >
          <Accordion type="multiple">
            <FilterItem
              title="Base"
              tip="Find swaps matching the base token address or symbol"
            >
              <Input2
                type="text"
                placeholder="e.g. 0x0..."
                className="pl-2 transition-all duration-500 hover:scale-x-[1.01] focus:scale-x-[1.01]"
                value={values.base || ""}
                onChange={(e) => {
                  setValues({ ...values, base: e.target.value });
                }}
              />
            </FilterItem>
            <FilterItem
              title="Quote"
              tip="Find swaps matching the quote token address or symbol"
            >
              <Input2
                type="text"
                placeholder="e.g. 0x0..."
                className="pl-2 transition-all duration-500 hover:scale-x-[1.01] focus:scale-x-[1.01]"
                value={values.quote || ""}
                onChange={(e) => {
                  setValues({ ...values, quote: e.target.value });
                }}
              />
            </FilterItem>
            <FilterItem
              title="By Market ID"
              tip="Find swaps from a specific market matching the given address"
            >
              <Input2
                type="text"
                placeholder="e.g. 0x0..."
                className="pl-2  transition-all duration-500 hover:scale-x-[1.01] focus:scale-x-[1.01]"
                value={values.market || ""}
                onChange={(e) => {
                  setValues({ ...values, market: e.target.value });
                }}
              />
            </FilterItem>

            <FilterItem title="Type" tip="Find swaps of a certain type">
              <div className="mr-1">
                <Select2
                  value={values.type || ""}
                  className="hover:scale-x-[1.03] focus:scale-x-[1.03]"
                  placeholder="All"
                  onValueChange={(val) => {
                    setValues({ ...values, type: val });
                  }}
                >
                  <>
                    <SelectItem value="">All</SelectItem>
                    <SelectItem value="instant">Instant</SelectItem>
                    <SelectItem value="interactive">Interactive</SelectItem>
                  </>
                </Select2>
              </div>
            </FilterItem>

            <FilterItem
              title="Status"
              tip="Find swaps based on their current status"
            >
              <div className="mr-1">
                <Select2
                  value={values.status || ""}
                  className="hover:scale-x-[1.03] focus:scale-x-[1.03]"
                  placeholder="All"
                  onValueChange={(val) => {
                    setValues({ ...values, status: val });
                  }}
                >
                  <>
                    <SelectItem value="">All</SelectItem>
                    <SelectItem value="unpaid">Unpaid</SelectItem>
                    <SelectItem value="paid">Paid</SelectItem>
                    <SelectItem value="released">Released</SelectItem>
                    <SelectItem value="disputed">Disputed</SelectItem>
                    <SelectItem value="cancelled">Cancelled</SelectItem>
                  </>
                </Select2>
              </div>
            </FilterItem>

            <FilterItem
              title="Date Range"
              tip="Find swaps created within a certain date range"
            >
              <div className="flex w-full gap-3">
                <div className="flex flex-1 flex-col gap-2 tracking-wide">
                  <div className="text-xs text-gray-400 font-light">
                    From Date:
                  </div>
                  <Calendar2
                    className="w-full"
                    defaultValue={
                      values.from_date ? new Date(values.from_date) : undefined
                    }
                    onSelect={(date) => {
                      setValues({
                        ...values,
                        from_date: date.toLocaleDateString("en-US"),
                      });
                    }}
                  />
                </div>

                <div className="flex flex-1 flex-col gap-2 tracking-wide">
                  <div className="text-xs text-gray-400 font-light">
                    To Date:
                  </div>
                  <Calendar2
                    className="w-full"
                    defaultValue={
                      values.to_date ? new Date(values.to_date) : undefined
                    }
                    onSelect={(date) => {
                      setValues({
                        ...values,
                        to_date: date.toLocaleDateString("en-US"),
                      });
                    }}
                  />
                </div>
              </div>
            </FilterItem>
          </Accordion>
        </ScrollArea>
      </ScrollOverflowIndicator>
      <div className="px-5 py-5 pt-3">
        <Button
          size="full"
          variant="outline"
          onClick={() => {
            const cloned = { ...values };
            navigate({
              pathname: "/my/swaps",
              search: new URLSearchParams(
                rmUndefinedAndNull(cloned, true),
              ).toString(),
            });
          }}
        >
          Filter
        </Button>
      </div>
    </div>
  );
}
