import React from "react";

export default function IconReview(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 25 25"
      fill="currentColor"
      {...props}
    >
      <path d="M24.5,4c0-1.5-1.2-2.7-2.7-2.7H3.2C1.7,1.3,0.5,2.5,0.5,4v12.7c0,1.5,1.2,2.7,2.7,2.7h0.7v3c0,0.5,0.3,1,0.7,1.2 c0.2,0.1,0.4,0.1,0.6,0.1c0.3,0,0.6-0.1,0.8-0.3l5.6-4.1h10.3c1.5,0,2.7-1.2,2.7-2.7V4z M17.3,9.6l-1.6,1.5l0.4,2.2 c0.1,0.4-0.1,0.8-0.5,1.1c-0.3,0.3-0.8,0.3-1.2,0.1l-1.9-1l-2,1c-0.2,0.1-0.4,0.1-0.5,0.1c-0.2,0-0.5-0.1-0.7-0.2 c-0.4-0.3-0.5-0.7-0.5-1.1l0.4-2.2L7.7,9.6C7.4,9.2,7.3,8.8,7.4,8.4C7.5,8,7.9,7.7,8.3,7.6l2.2-0.3l1-2c0.2-0.4,0.6-0.6,1-0.6 c0.4,0,0.8,0.2,1,0.6l1,2l2.2,0.3c0.4,0.1,0.8,0.4,0.9,0.8C17.7,8.8,17.6,9.2,17.3,9.6z"></path>
    </svg>
  );
}
