import React from "react";

export default function IconInboxChecked(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      fill="currentColor"
      {...props}
      viewBox="0 0 48 48"
    >
      <path d="M 35 2 C 28.925 2 24 6.925 24 13 C 24 19.075 28.925 24 35 24 C 41.075 24 46 19.075 46 13 C 46 6.925 41.075 2 35 2 z M 12.5 6 C 8.92 6 6 8.92 6 12.5 L 6 35.5 C 6 39.08 8.92 42 12.5 42 L 35.5 42 C 39.08 42 42 39.08 42 35.5 L 42 26 L 40.5 26 L 39 26 L 29.919922 26 A 1.50015 1.50015 0 0 0 28.419922 27.5 C 28.419922 29.10675 27.841159 30.13956 27.007812 30.875 C 26.174467 31.61044 25.02588 32 24 32 C 22.97412 32 21.825533 31.61044 20.992188 30.875 C 20.158842 30.13956 19.580078 29.10675 19.580078 27.5 A 1.50015 1.50015 0 0 0 18.080078 26 L 9 26 L 9 12.5 C 9 10.57 10.57 9 12.5 9 L 22.630859 9 C 22.970859 7.93 23.450781 6.93 24.050781 6 L 12.5 6 z M 41 8 C 41.25575 8 41.511531 8.0974688 41.707031 8.2929688 C 42.098031 8.6839688 42.098031 9.3160312 41.707031 9.7070312 L 33.707031 17.707031 C 33.512031 17.902031 33.256 18 33 18 C 32.744 18 32.487969 17.902031 32.292969 17.707031 L 28.292969 13.707031 C 27.901969 13.316031 27.901969 12.683969 28.292969 12.292969 C 28.683969 11.901969 29.316031 11.901969 29.707031 12.292969 L 33 15.585938 L 40.292969 8.2929688 C 40.488469 8.0974688 40.74425 8 41 8 z M 9 29 L 16.894531 29 C 17.225629 30.675386 17.90699 32.153508 19.007812 33.125 C 20.440717 34.38956 22.25288 35 24 35 C 25.74712 35 27.559283 34.38956 28.992188 33.125 C 30.09301 32.153508 30.774371 30.675386 31.105469 29 L 39 29 L 39 35.5 C 39 37.43 37.43 39 35.5 39 L 12.5 39 C 10.57 39 9 37.43 9 35.5 L 9 29 z"></path>
    </svg>
  );
}
