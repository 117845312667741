function IconMediation(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
      x="0"
      y="0"
      viewBox="0 0 50 50"
    >
      <path d="M26 3s-1.397 1.945-1.484 2.863C23.864 5.37 23.13 5 22.363 5c-1.663 0-2.705.594-3.623 1.12-.826.471-1.539.88-2.74.88h-.002a.99.99 0 00-.787.389c-.055.07-.102.149-.137.234l-2.674 6.15-2.318 5.329A.987.987 0 0010 19.5c0 2.523 2.636 4.5 6 4.5s6-1.977 6-4.5a.997.997 0 00-.082-.398L17.455 8.84c.934-.215 1.636-.618 2.28-.985C20.57 7.377 21.23 7 22.362 7c.472 0 1.159.577 1.782 1.258-.092.23-.145.48-.145.742a1.99 1.99 0 001 1.723V27a1 1 0 102 0V10.723a1.99 1.99 0 00.855-2.465C28.478 7.577 29.165 7 29.637 7c1.133 0 1.794.378 2.63.857.644.367 1.344.767 2.278.983L32.4 13.773l-2.318 5.329A.987.987 0 0030 19.5c0 2.523 2.636 4.5 6 4.5s6-1.977 6-4.5a1.01 1.01 0 00-.082-.4L36.926 7.62a1.014 1.014 0 00-.276-.38A1 1 0 0036.002 7H36c-1.201 0-1.914-.409-2.74-.88C32.342 5.593 31.3 5 29.637 5c-.768 0-1.501.37-2.153.863C27.397 4.945 26 3 26 3zm-10 7.508L19.258 18h-6.516L16 10.508zm20 0L39.258 18h-6.516L36 10.508zm8.346 15.472a3.734 3.734 0 00-2.12.575l-9.349 5.761A3.92 3.92 0 0134 35.055a3.94 3.94 0 01-4.838 3.83c-.769-.182-10.593-3.123-11.71-3.457a1.001 1.001 0 01.573-1.916c4.366 1.308 11.059 3.3 11.598 3.427A1.936 1.936 0 0032 35.056c0-.688-.358-1.308-.957-1.656-.291-.169-10.162-4.484-12.147-5.355a12.566 12.566 0 00-9.834-.094l-6.447 2.686c-.51.21-.615.673-.615 1.306v12.045a1 1 0 001 1c.305 0 .549-.164.588-.193l3.484-2.557c1.776-1.303 4.144-1.59 6.227-.732l11.695 4.146a5.992 5.992 0 005.639-.875l15.736-11.923a3.762 3.762 0 001.049-5.118 3.77 3.77 0 00-3.072-1.756z"></path>
    </svg>
  );
}

export default IconMediation;
