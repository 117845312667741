import React from "react";

export function IconFlagSKorea(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      {...props}
      viewBox="0 0 48 48"
    >
      <circle cx="24" cy="24" r="20" fill="#f5f5f5"></circle>
      <path
        fill="#263238"
        d="M11.91,21.096l4.355-6.711l0.59,0.383L12.5,21.475L11.91,21.096z"
      ></path>
      <path
        fill="#263238"
        d="M8.793,28.928l0.672-0.434l4.348,6.715l-0.668,0.434L8.793,28.928z"
      ></path>
      <path
        fill="#263238"
        d="M32.664,13.74l0.668-0.434l4.426,6.781l-0.672,0.438L32.664,13.74z"
      ></path>
      <path
        fill="#263238"
        d="M10.344,20.127l4.355-6.711l0.586,0.379l-4.355,6.711L10.344,20.127z"
      ></path>
      <path
        fill="#263238"
        d="M33.445,18.4l0.586-0.383l2.016,3.105l-0.59,0.383L33.445,18.4z"
      ></path>
      <path
        fill="#263238"
        d="M36.453,31.643l2.016-3.105l0.586,0.383l-2.016,3.102L36.453,31.643z"
      ></path>
      <path
        fill="#263238"
        d="M12.637,31.51l0.586-0.379l2.016,3.102l-0.586,0.383L12.637,31.51z"
      ></path>
      <path
        fill="#263238"
        d="M36.559,16.416l0.586-0.383l2.027,3.094l-0.586,0.383L36.559,16.416z"
      ></path>
      <path
        fill="#263238"
        d="M33.371,29.619l2.016-3.105l0.586,0.383l-2.016,3.102L33.371,29.619z"
      ></path>
      <path
        fill="#263238"
        d="M34.937,30.588l2.023-3.102l0.668,0.438l-2.02,3.102L34.937,30.588z"
      ></path>
      <path
        fill="#263238"
        d="M31.148,14.768l0.586-0.383l2.027,3.094l-0.586,0.383L31.148,14.768z"
      ></path>
      <path
        fill="#263238"
        d="M34.105,35.221l1.996-3.117l0.59,0.379l-2,3.113L34.105,35.221z"
      ></path>
      <path
        fill="#263238"
        d="M10.352,27.865l0.672-0.434l2,3.113l-0.672,0.434L10.352,27.865z"
      ></path>
      <path
        fill="#263238"
        d="M34.266,12.771l0.586-0.387l2.031,3.094l-0.586,0.383L34.266,12.771z"
      ></path>
      <path
        fill="#263238"
        d="M31.008,33.17l2.02-3.098l0.672,0.438l-2.023,3.098L31.008,33.17z"
      ></path>
      <path
        fill="#263238"
        d="M32.535,34.24l2.004-3.109l0.672,0.434l-2.004,3.109L32.535,34.24z"
      ></path>
      <path
        fill="#263238"
        d="M8.758,19.139l4.43-6.781l0.672,0.438l-4.43,6.781L8.758,19.139z"
      ></path>
      <path
        fill="#263238"
        d="M11.855,26.85l0.59-0.383l4.348,6.715l-0.586,0.383L11.855,26.85z"
      ></path>
      <path
        fill="#f44336"
        d="M20.5,27.482c1.924,0,3.5-1.576,3.5-3.5s1.576-3.5,3.5-3.5s3.5,1.576,3.5,3.5c0-3.849-3.151-7-7-7	s-7,3.151-7,7C17,25.907,18.576,27.482,20.5,27.482z"
      ></path>
      <path
        fill="#3949ab"
        d="M27.5,20.482c-1.924,0-3.5,1.576-3.5,3.5s-1.576,3.5-3.5,3.5s-3.5-1.576-3.5-3.5c0,3.849,3.151,7,7,7	s7-3.151,7-7C31,22.058,29.424,20.482,27.5,20.482z"
      ></path>
    </svg>
  );
}
