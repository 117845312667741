import * as React from 'react';

export function IconMessageReceived(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='20' height='20' viewBox='0 0 24 24' {...props}>
			<path
				fill='currentColor'
				d='M 3 4 C 1.895 4 1 4.895 1 6 L 1 18 C 1 19.105 1.895 20 3 20 L 11 20 L 13 20 L 11 18 L 3 18 L 3 8.0019531 L 11 13 L 19 8.0019531 L 19 15.757812 L 21 13.757812 L 21 6 C 21 4.895 20.105 4 19 4 L 3 4 z M 3 6 L 19 6 L 19 6.0019531 L 11 11 L 3 6.0019531 L 3 6 z M 22.589844 14.996094 L 18 19.585938 L 15.414062 17 L 14 18.414062 L 18 22.414062 L 24.003906 16.410156 L 22.589844 14.996094 z'
			></path>
		</svg>
	);
}
