import React from "react";

export function IconPlus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" viewBox="0 0 24 24" height="20px" width="20px" {...props}>
      <path
        fill="currentColor"
        d="M12 4a1 1 0 00-1 1v6H5a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2h-6V5a1 1 0 00-1-1z"
      />
    </svg>
  );
}
