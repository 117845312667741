import React from "react";

export function IconFlagIndia(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      {...props}
      viewBox="0 0 48 48"
    >
      <path
        fill="#f5f5f5"
        d="M44,24c0-2.467-0.468-4.818-1.284-7H5.284C4.468,19.182,4,21.533,4,24s0.468,4.818,1.284,7h37.431	C43.532,28.818,44,26.467,44,24z"
      ></path>
      <path
        fill="#008"
        d="M24,19c-2.762,0-5,2.238-5,5s2.238,5,5,5s5-2.238,5-5S26.762,19,24,19z M24,28.376	c-2.418,0-4.376-1.958-4.376-4.376s1.958-4.376,4.376-4.376s4.376,1.958,4.376,4.376S26.418,28.376,24,28.376z"
      ></path>
      <path
        fill="#008"
        d="M24.874,24c0,0.485-0.39,0.874-0.874,0.874s-0.874-0.39-0.874-0.874s0.39-0.874,0.874-0.874	S24.874,23.515,24.874,24z"
      ></path>
      <path
        fill="#008"
        d="M28.371,24.355c-0.12-0.015-0.23,0.065-0.245,0.185c-0.015,0.12,0.065,0.23,0.185,0.245	c0.12,0.02,0.23-0.065,0.245-0.185C28.575,24.48,28.491,24.37,28.371,24.355z"
      ></path>
      <path
        fill="#008"
        d="M24,28.376l0.15-2.627L24,24.5l-0.15,1.249L24,28.376z"
      ></path>
      <path
        fill="#008"
        d="M28.131,25.474c-0.115-0.045-0.24,0.005-0.29,0.12c-0.045,0.11,0.01,0.24,0.12,0.285	c0.11,0.045,0.24-0.005,0.285-0.12C28.296,25.648,28.241,25.518,28.131,25.474z"
      ></path>
      <path
        fill="#008"
        d="M22.866,28.226l0.824-2.498l0.18-1.244l-0.47,1.169L22.866,28.226z"
      ></path>
      <path
        fill="#008"
        d="M27.606,26.493c-0.095-0.075-0.23-0.06-0.305,0.04c-0.075,0.095-0.055,0.23,0.04,0.305	c0.095,0.075,0.235,0.055,0.305-0.04C27.721,26.702,27.701,26.562,27.606,26.493z"
      ></path>
      <path
        fill="#008"
        d="M21.812,27.791l1.444-2.203l0.495-1.154l-0.754,1.009L21.812,27.791z"
      ></path>
      <path
        fill="#008"
        d="M26.842,27.337c-0.075-0.095-0.215-0.11-0.31-0.04c-0.095,0.075-0.115,0.215-0.04,0.31	c0.075,0.095,0.21,0.115,0.305,0.04C26.897,27.571,26.912,27.437,26.842,27.337z"
      ></path>
      <path
        fill="#008"
        d="M20.903,27.097l1.963-1.753l0.779-0.989l-0.989,0.779L20.903,27.097z"
      ></path>
      <path
        fill="#008"
        d="M25.878,27.956c-0.045-0.11-0.175-0.16-0.285-0.115c-0.11,0.045-0.165,0.175-0.12,0.285	c0.05,0.11,0.175,0.165,0.285,0.12C25.873,28.196,25.923,28.071,25.878,27.956z"
      ></path>
      <path
        fill="#008"
        d="M20.209,26.188l2.348-1.184l1.009-0.754l-1.154,0.495L20.209,26.188z"
      ></path>
      <path
        fill="#008"
        d="M24.789,28.311c-0.015-0.12-0.125-0.205-0.245-0.19c-0.12,0.02-0.205,0.13-0.19,0.25	c0.02,0.12,0.13,0.2,0.25,0.185C24.724,28.54,24.804,28.431,24.789,28.311z"
      ></path>
      <path
        fill="#008"
        d="M19.774,25.134l2.572-0.534l1.169-0.47l-1.244,0.18L19.774,25.134z"
      ></path>
      <path
        fill="#008"
        d="M23.456,28.126c-0.12-0.02-0.23,0.065-0.245,0.185c-0.015,0.12,0.07,0.23,0.19,0.245	c0.12,0.02,0.23-0.065,0.245-0.185C23.66,28.251,23.575,28.141,23.456,28.126z"
      ></path>
      <path
        fill="#008"
        d="M19.624,24l2.627,0.15L23.5,24l-1.249-0.15L19.624,24z"
      ></path>
      <path
        fill="#008"
        d="M22.412,27.841c-0.11-0.045-0.24,0.01-0.285,0.12c-0.05,0.11,0.005,0.24,0.115,0.285	c0.115,0.045,0.24-0.005,0.29-0.115C22.576,28.016,22.521,27.891,22.412,27.841z"
      ></path>
      <path
        fill="#008"
        d="M19.774,22.866l2.498,0.824l1.244,0.18l-1.169-0.47L19.774,22.866z"
      ></path>
      <path
        fill="#008"
        d="M21.473,27.297c-0.095-0.07-0.235-0.055-0.305,0.04c-0.075,0.1-0.06,0.235,0.04,0.31	c0.095,0.07,0.23,0.055,0.305-0.04C21.587,27.506,21.567,27.372,21.473,27.297z"
      ></path>
      <path
        fill="#008"
        d="M20.209,21.812l2.203,1.444l1.154,0.495l-1.009-0.754L20.209,21.812z"
      ></path>
      <path
        fill="#008"
        d="M20.703,26.532c-0.075-0.095-0.21-0.115-0.305-0.04c-0.095,0.07-0.115,0.21-0.04,0.305	c0.07,0.095,0.21,0.115,0.305,0.04C20.758,26.762,20.778,26.627,20.703,26.532z"
      ></path>
      <path
        fill="#008"
        d="M20.903,20.903l1.753,1.963l0.989,0.779l-0.779-0.989L20.903,20.903z"
      ></path>
      <path
        fill="#008"
        d="M20.159,25.588c-0.045-0.11-0.175-0.165-0.285-0.115c-0.11,0.045-0.165,0.175-0.12,0.285	c0.05,0.11,0.175,0.165,0.29,0.12C20.154,25.828,20.209,25.703,20.159,25.588z"
      ></path>
      <path
        fill="#008"
        d="M21.812,20.209l1.184,2.348l0.754,1.009l-0.495-1.154L21.812,20.209z"
      ></path>
      <path
        fill="#008"
        d="M19.879,24.539c-0.02-0.12-0.13-0.2-0.245-0.185c-0.12,0.015-0.205,0.125-0.19,0.245	c0.015,0.12,0.125,0.205,0.245,0.185C19.809,24.769,19.894,24.659,19.879,24.539z"
      ></path>
      <path
        fill="#008"
        d="M22.866,19.774l0.534,2.572l0.47,1.169l-0.18-1.244L22.866,19.774z"
      ></path>
      <path
        fill="#008"
        d="M19.689,23.211c-0.12-0.015-0.23,0.07-0.245,0.19c-0.015,0.12,0.07,0.23,0.19,0.245	c0.12,0.015,0.23-0.07,0.245-0.19C19.894,23.336,19.809,23.226,19.689,23.211z"
      ></path>
      <path
        fill="#008"
        d="M24,19.624l-0.15,2.627L24,23.5l0.15-1.249L24,19.624z"
      ></path>
      <path
        fill="#008"
        d="M20.039,22.122c-0.11-0.045-0.24,0.01-0.285,0.12s0.005,0.24,0.12,0.285	c0.11,0.045,0.24-0.005,0.285-0.12C20.204,22.297,20.149,22.167,20.039,22.122z"
      ></path>
      <path
        fill="#008"
        d="M25.134,19.774l-0.824,2.498l-0.18,1.244l0.47-1.169L25.134,19.774z"
      ></path>
      <path
        fill="#008"
        d="M20.663,21.163c-0.1-0.075-0.235-0.06-0.31,0.04c-0.07,0.095-0.055,0.23,0.04,0.305	c0.1,0.075,0.235,0.055,0.31-0.04C20.773,21.373,20.758,21.233,20.663,21.163z"
      ></path>
      <path
        fill="#008"
        d="M26.188,20.209l-1.444,2.203l-0.495,1.154l0.754-1.009L26.188,20.209z"
      ></path>
      <path
        fill="#008"
        d="M21.512,20.394c-0.075-0.095-0.21-0.115-0.305-0.04c-0.1,0.075-0.115,0.21-0.045,0.31	c0.075,0.095,0.215,0.11,0.31,0.04C21.567,20.628,21.587,20.489,21.512,20.394z"
      ></path>
      <path
        fill="#008"
        d="M27.097,20.903l-1.963,1.753l-0.779,0.989l0.989-0.779L27.097,20.903z"
      ></path>
      <path
        fill="#008"
        d="M22.526,19.874c-0.045-0.11-0.175-0.165-0.285-0.115c-0.11,0.045-0.165,0.17-0.12,0.285	c0.045,0.11,0.175,0.165,0.285,0.115C22.521,20.114,22.571,19.989,22.526,19.874z"
      ></path>
      <path
        fill="#008"
        d="M27.791,21.812l-2.348,1.184l-1.009,0.754l1.154-0.495L27.791,21.812z"
      ></path>
      <path
        fill="#008"
        d="M23.645,19.629c-0.015-0.12-0.125-0.2-0.245-0.185c-0.12,0.015-0.205,0.125-0.19,0.245	c0.015,0.12,0.125,0.205,0.245,0.185C23.575,19.859,23.66,19.749,23.645,19.629z"
      ></path>
      <path
        fill="#008"
        d="M28.226,22.866l-2.572,0.534l-1.169,0.47l1.244-0.18L28.226,22.866z"
      ></path>
      <path
        fill="#008"
        d="M24.599,19.445c-0.12-0.015-0.23,0.07-0.245,0.19c-0.015,0.12,0.07,0.23,0.19,0.245	c0.12,0.015,0.23-0.07,0.245-0.19C24.804,19.569,24.719,19.46,24.599,19.445z"
      ></path>
      <path
        fill="#008"
        d="M28.376,24l-2.627-0.15L24.5,24l1.249,0.15L28.376,24z"
      ></path>
      <path
        fill="#008"
        d="M25.758,19.754c-0.11-0.05-0.24,0.005-0.285,0.115c-0.045,0.115,0.005,0.24,0.115,0.285	c0.115,0.05,0.24-0.005,0.29-0.115C25.923,19.924,25.868,19.799,25.758,19.754z"
      ></path>
      <path
        fill="#008"
        d="M28.226,25.134l-2.498-0.824l-1.244-0.18l1.169,0.47L28.226,25.134z"
      ></path>
      <path
        fill="#008"
        d="M26.797,20.354c-0.095-0.07-0.23-0.055-0.305,0.04c-0.075,0.1-0.055,0.235,0.04,0.31	c0.095,0.07,0.235,0.055,0.31-0.04C26.912,20.563,26.897,20.429,26.797,20.354z"
      ></path>
      <path
        fill="#008"
        d="M27.791,26.188l-2.203-1.444l-1.154-0.495l1.009,0.754L27.791,26.188z"
      ></path>
      <path
        fill="#008"
        d="M27.646,21.203c-0.075-0.095-0.215-0.115-0.31-0.04c-0.095,0.075-0.115,0.21-0.04,0.305	c0.075,0.095,0.21,0.115,0.31,0.04C27.701,21.438,27.716,21.298,27.646,21.203z"
      ></path>
      <path
        fill="#008"
        d="M27.097,27.097l-1.753-1.963l-0.989-0.779l0.779,0.989L27.097,27.097z"
      ></path>
      <path
        fill="#008"
        d="M28.246,22.242c-0.05-0.11-0.175-0.165-0.285-0.12c-0.115,0.05-0.165,0.175-0.12,0.285	c0.045,0.115,0.175,0.165,0.285,0.12C28.236,22.482,28.291,22.352,28.246,22.242z"
      ></path>
      <path
        fill="#008"
        d="M26.188,27.791l-1.184-2.348l-0.754-1.009l0.495,1.154L26.188,27.791z"
      ></path>
      <path
        fill="#008"
        d="M28.555,23.401c-0.015-0.12-0.125-0.205-0.245-0.19c-0.12,0.02-0.205,0.125-0.185,0.245	c0.015,0.12,0.125,0.205,0.245,0.19C28.491,23.63,28.575,23.52,28.555,23.401z"
      ></path>
      <path
        fill="#008"
        d="M25.134,28.226l-0.534-2.572l-0.47-1.169l0.18,1.244L25.134,28.226z"
      ></path>
      <path
        fill="#388e3c"
        d="M42.716,31H5.284C8.125,38.588,15.421,44,24,44S39.875,38.588,42.716,31z"
      ></path>
      <path
        fill="#ff9800"
        d="M24,4C15.421,4,8.125,9.412,5.284,17h37.431C39.875,9.412,32.579,4,24,4z"
      ></path>
    </svg>
  );
}
