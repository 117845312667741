import React from "react";

export default function IconCoinStack2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      fill="currentColor"
      {...props}
      viewBox="1 0 30 30"
    >
      <path d="M 11 5 C 5.39 5 1 7.64 1 11 L 1 21 C 1 24.36 5.39 27 11 27 A 14.74 14.74 0 0 0 17.300781 25.660156 A 9 9 0 1 0 22 9 A 9.07 9.07 0 0 0 20.509766 9.1308594 C 19.209766 6.6908594 15.44 5 11 5 z M 11 7 C 14.69 7 17.449766 8.2692188 18.509766 9.6992188 A 9 9 0 0 0 13.619141 14.75 A 15 15 0 0 1 11 15 C 6.29 15 3 12.89 3 11 C 3 9.11 6.29 7 11 7 z M 21.708984 11.005859 A 7 7 0 0 1 29 18 A 7 7 0 0 1 22 25 A 7 7 0 0 1 21.708984 11.005859 z M 22 13 A 5 5 0 1 0 27 18 A 5 5 0 0 0 22 13 z M 3 14.630859 A 13 13 0 0 0 11 17 A 17.86 17.86 0 0 0 13.080078 16.859375 A 8.5 8.5 0 0 0 13 18 A 9.16 9.16 0 0 0 13.189453 19.830078 A 12.18 12.18 0 0 1 11.849609 19.970703 A 12.34 12.34 0 0 1 10.130859 19.970703 A 10 10 0 0 1 3.9003906 17.769531 A 2.45 2.45 0 0 1 3 16 L 3 14.630859 z M 21.875 15.001953 A 3 3 0 0 1 25 18 A 3 3 0 0 1 22 21 A 3 3 0 0 1 21.875 15.001953 z M 3 19.640625 A 12.51 12.51 0 0 0 10 22 A 16 16 0 0 0 12 22 A 14.76 14.76 0 0 0 13.859375 21.769531 A 8.91 8.91 0 0 0 15.570312 24.289062 A 13.51 13.51 0 0 1 11 25 C 6.29 25 3 22.89 3 21 L 3 19.640625 z"></path>
    </svg>
  );
}
