/**
 * This code was generated by v0 by Vercel.
 * @see https://v0.dev/t/sROuQ0Cdc8X
 */
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useConnectorContext } from '../../hooks/connectors/useConnectorContext';
import { cn, shortenAddress } from '../../libs/helpers';
import EmojiAvatar from '../avatar/EmojiAvatar';
import { AvatarSize } from '../avatar/useAvatar';
import { Badge } from '../ui/badge';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';
import { PointSearchBar } from './PointSearchBar';

export function ReferralLeaderboard({
	points,
	addressPoint,
	total,
}: {
	points: Point[];
	addressPoint: Point[];
	total: number;
}) {
	const [searchParams] = useSearchParams();
	const targetPoints = searchParams.get('address') ? addressPoint : points;
	const { address } = useConnectorContext();

	return (
		<div className='flex flex-col w-full'>
			<main className='flex flex-col gap-4 p-4 pt-0 md:gap-7 md:p-7 md:pt-0'>
				<div className='grid gap-4 md:grid-cols-3'>
					{points.length > 0 && (
						<Card className='bg-card-background tracking-wider border-yellow-500'>
							<CardHeader className='flex flex-row items-center justify-between pb-2 space-y-0'>
								<CardTitle className='text-sm font-medium text-gray-100 tracking-wider'>1st Place</CardTitle>
								<TrophyIcon className='w-4 h-4 text-yellow-500' />
							</CardHeader>
							<CardContent className='flex items-center gap-2'>
								<EmojiAvatar size={AvatarSize.Medium} randomStr={points[0].referee} />
								<div>
									<div className='text-sm lg:text-lg font-normal text-gray-100'>
										{shortenAddress(points[0].referee)}
									</div>
									<p className='text-xs lg:text-sm text-gray-400'>{parseInt(points[0].totalPoints)} points</p>
								</div>
							</CardContent>
						</Card>
					)}

					{points.length > 1 && (
						<Card className='bg-card-background border-[silver] tracking-wider'>
							<CardHeader className='flex flex-row items-center justify-between pb-2 space-y-0'>
								<CardTitle className='text-sm font-medium text-gray-100 tracking-wider'>2nd Place</CardTitle>
								<TrophyIcon className='w-4 h-4 text-[silver]' />
							</CardHeader>
							<CardContent className='flex items-center gap-2'>
								<EmojiAvatar size={AvatarSize.Medium} randomStr={points[1].referee} />
								<div>
									<div className='text-sm lg:text-lg font-normal text-gray-100'>
										{shortenAddress(points[1].referee)}
									</div>
									<p className='text-xs lg:text-sm text-gray-400'>{parseInt(points[1].totalPoints)} points</p>
								</div>
							</CardContent>
						</Card>
					)}

					{points.length > 2 && (
						<Card className='bg-card-background border-yellow-600 tracking-wider'>
							<CardHeader className='flex flex-row items-center justify-between pb-2 space-y-0'>
								<CardTitle className='text-sm font-medium text-gray-100 tracking-wider'>3rd Place</CardTitle>
								<TrophyIcon className='w-4 h-4 text-yellow-600' />
							</CardHeader>
							<CardContent className='flex items-center gap-2'>
								<EmojiAvatar size={AvatarSize.Medium} randomStr={points[2].referee} />
								<div>
									<div className='text-sm lg:text-lg font-normal text-gray-100'>
										{shortenAddress(points[2].referee)}
									</div>
									<p className='text-xs lg:text-sm text-gray-400'>{points[2].totalPoints} points</p>
								</div>
							</CardContent>
						</Card>
					)}
				</div>
				<div className='md:hidden'>
					<PointSearchBar />
				</div>
				<div className='text-gray-400 font-light pl-3'>
					{!_.isEmpty(searchParams.get('address')) && targetPoints.length > 0 && <>Showing 1 of 1 result</>}

					{_.isEmpty(searchParams.get('address')) && (
						<>
							Showing {points.length} of {total} results
						</>
					)}
				</div>
				<div>
					<Card className='bg-card-background border-card-border tracking-wider'>
						<Table>
							<TableHeader>
								<TableRow className='border-gray-800 hover:bg-transparent'>
									<TableHead className='w-[80px] font-light'>Rank</TableHead>
									<TableHead className='font-light'>Address</TableHead>

									<TableHead className='text-right font-light'>Points</TableHead>
								</TableRow>
							</TableHeader>
							<TableBody className='text-gray-300'>
								{targetPoints.map((point, index) => (
									<TableRow
										key={`${point.address}_${point.totalPoints}`}
										className={cn('border-gray-800 hover:bg-transparent ', {
											'!text-yellow-500': index == 0,
											'!text-[silver]': index == 1,
											'!text-yellow-600': index == 2,
										})}
									>
										<TableCell className='font-medium'>{addressPoint.length == 0 ? index + 1 : point.rank}</TableCell>

										<TableCell>
											{shortenAddress(point.referee)}
											{address == point.referee && (
												<Badge className='ml-2 rounded-full bg-gray-600 text-gray-900 hidden md:inline'>You</Badge>
											)}
										</TableCell>

										<TableCell className='text-right'>{parseInt(point.totalPoints)}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Card>
					{targetPoints.length == 0 && (
						<div className='w-full text-center p-5 text-gray-400 font-light tracking-wider'>No result found</div>
					)}
				</div>
			</main>
		</div>
	);
}

function TrophyIcon(props) {
	return (
		<svg
			{...props}
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		>
			<path d='M6 9H4.5a2.5 2.5 0 0 1 0-5H6' />
			<path d='M18 9h1.5a2.5 2.5 0 0 0 0-5H18' />
			<path d='M4 22h16' />
			<path d='M10 14.66V17c0 .55-.47.98-.97 1.21C7.85 18.75 7 20.24 7 22' />
			<path d='M14 14.66V17c0 .55.47.98.97 1.21C16.15 18.75 17 20.24 17 22' />
			<path d='M18 2H6v7a6 6 0 0 0 12 0V2Z' />
		</svg>
	);
}
