import React from "react";

export function IconFlagChina(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      {...props}
      viewBox="0 0 48 48"
    >
      <circle cx="24" cy="24" r="20" fill="#ff3d00"></circle>
      <path
        fill="#ffeb3b"
        d="M12,18.574l1.18,3.75H17l-3.09,2.129l1.18,3.633L12,25.84l-3.09,2.246l1.18-3.633L7,22.324h3.82 L12,18.574z"
      ></path>
      <path
        fill="#ffeb3b"
        d="M19.473,16.063l0.355,1.125h1.145l-0.926,0.637l0.355,1.09l-0.93-0.676l-0.926,0.676l0.355-1.09 l-0.93-0.637h1.148L19.473,16.063z"
      ></path>
      <path
        fill="#ffeb3b"
        d="M19.5,29.086l0.355,1.125H21l-0.926,0.637l0.352,1.09L19.5,31.266l-0.926,0.672l0.352-1.09 L18,30.211h1.145L19.5,29.086z"
      ></path>
      <path
        fill="#ffeb3b"
        d="M23.5,20.086l0.355,1.125H25l-0.926,0.637l0.352,1.09L23.5,22.266l-0.926,0.672l0.352-1.09 L22,21.211h1.145L23.5,20.086z"
      ></path>
      <path
        fill="#ffeb3b"
        d="M23.5,25.086l0.355,1.125H25l-0.926,0.637l0.352,1.09L23.5,27.266l-0.926,0.672l0.352-1.09 L22,26.211h1.145L23.5,25.086z"
      ></path>
    </svg>
  );
}
